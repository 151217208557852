<template>
<div class="dash-font">
  <header></header>
  <!-- 페이지시작 -->
  <section class="cover">
    <div class="cover_img_baby">
      <ul class="cover_psn_d">
        <li><span>Sample ID :</span> {{ sample.code }}<span>고객명 :</span> {{ sample.name }}<span>성별 :</span> {{ sample.sex }}</li>
        <li><span>발급일 :</span> {{ moment(new Date()).format('YYYY년 M월 D일') }} <span>발급처 :</span> AiBiotics<span>문의처 :</span> 070.7703.0380 </li>
      </ul>
    </div>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_microbiome_explain.png" alt="microbiome"><span class="miso"></span></h2>
  </div>
  <section>
    <ul class="microbiome_txt">
      <li>
        <div>
          <strong>Microbiome 확인 하는 이유</strong>
          <span>
					인간의 몸에는 인간과 공생하는 또 다른 생명들이 존재합니다.<br>
					바로 ‘미생물’입니다.<br>
					인체에 존재하는 미생물의 90% 이상이 장(Gut)에 존재하며, 이 미생물의 총 무게는1.5~2Kg 정도로 인간 몸무게의 2~4%를 차지할 정도로 많고 다양합니다.
					특히 장에 사는 미생물들은 인간의 질병 90%와 관련이 있고, 면역 기능 중 70%를 담당하고 있어 면역과 소화에 절대적인 역할을 하고 있습니다.<br>
					따라서 미생물이 인체에 미치는 영향은 매우 크기 때문에 제 2의 유전자인 장 미생물 분석이 필요합니다.
					</span>
        </div>
      </li>
      <li>
        <div>
          <strong>최초의 1000일</strong>
          <span>
					‘엄마 배속에서 잉태된 순간부터 생후 두 돌까지’ ‘최초의 1000일’은 아기에게 매우 중요한 시기입니다. 영양적인 면, 여러 기관의 성장 및 발달, 장내환경 등이 완성되는 시간입니다.<br>
					인간은 타고난 건강 체질이 있는가 하면 후천적으로 건강해지는 사람이 있습니다.
					부모로 부터 받은 생물학적 유전과 더불어 부모의 생활에서 이식되는 사회적인 유전도 있습니다. 장내 미생물은 이런 두 가지 유전적인 요소를 더한 종합적인 결과이자 미래 건강의 원인이 되기도 합니다.<br><br>

					제왕절개나 자연분만, 그리고 모유 수유나 분유 수유의 차이에 대한 많은 이론들이 있지만 최초의 1000일 동안 잘 관리하는 것이 중요합니다.


					</span>
        </div>
      </li>
      <li>
        <div>
          <strong>인체는 생태계</strong>
          <span>
인간의 몸은 하나의 생태계이며 우주와도 같습니다. 수 십조의 미생물들이 인간의 몸에서 역할을 하며 공존하고 있습니다. 보이지 않는 아주 복잡한 나름대로의 규칙과 질서가 있지만 그 관계가 너무 다양하고 복잡하기 때문에 우리는 모두를 이해할 수 없을 뿐입니다.
하지만 그 균형이 깨어지거나 결핍이 발생할 때 복원하는 방법을 찾기 위해 첨단 기술을 이용한 장내 미생물의 균형을 측정합니다. 또한 균형을 복원하는 방법을 생활 전반의 균형에서부터 찾고 나에게 맞는 유산균과 음식을 찾아보고자 합니다.
					</span>
        </div>
      </li>
    </ul>
    <div class="tip_box txt_summary">
      <span>장내 미생물 검사에 의한 미생물 검출이 질병을 의미하지는 않습니다. 또한  검출 실패는 질병을 일으키는 미생물의 존재를  없음을 보증하지 않습니다. 이 검사에 의해 검출되지 않는 다른 유기체도 존재할 수 있습니다. 이 검사는 미생물 또는 그 항균 민감도를 확인하는 확립 된 방법을 대체하지 않습니다. 건강 상태에 설명 된 박테리아와 결과는 가능한 문제의 징후만을 제공합니다. 이것은 진단이 아니며 그렇게 간주 될 수 없습니다. 그러나 미래에 문제가 될 수 있는 가능성은 설명이 가능 할 수도 있습니다.</span>
    </div>
  </section>
  <div class="footer" style="margin-bottom:20px;">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span></span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full">
    <ul class="index_list">
      <li></li>
      <li>
        <ol>
          <li>
            <span>01</span>
            <strong> Introduction</strong>
          </li>
          <li>
            <span>02</span>
            <strong> Balance 평가</strong>
            <ul>
              <li>ㆍ3.1 Balance 1 : 성장</li>
              <li>ㆍ3.3 Balance 2 : 수면 </li>
              <li>ㆍ3.4 Balance 3 : 가족력 </li>
              <li>ㆍ3.5 Balance 4 : 장내 미생물 </li>
            </ul>
          </li>
          <li>
            <span>03</span>
            <strong> 다양성</strong>
          </li>
          <li>
            <span>04</span>
            <strong> Phylum (문)분석</strong>
          </li>
          <li>
            <span>05</span>
            <strong> Entero Type (장유형)분석</strong>
          </li>
          <li>
            <span>06</span>
            <strong> 유산균 분석</strong>
          </li>
          <li>
            <span>07</span>
            <strong> Summary</strong>
          </li>
        </ol>
      </li>
    </ul>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="full sv_baby">
    <table class="surveytb surveytb_1">
      <tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tr class="cate">
        <td colspan="6">아이정보</td>
      </tr>
      <tr class="title">
        <td>Sample ID</td>
        <td>작성일</td>
        <td>이름</td>
        <td>생년월일</td>
        <td>신장/체중</td>
        <td>BMI</td>
      </tr>
      <tr>
        <td>{{sample.code}}</td>
        <td>{{moment(new Date()).format('YYYY.MM.DD')}}</td>
        <td>{{survey.name}}</td>
        <td>{{survey.birthday_year+"."+survey.birthday_month+"."+survey.birthday_day}}</td>
        <td>{{survey.height}}cm / {{survey.weight}}kg</td>
        <td>{{survey.bmi}}</td>
      </tr>
      </tbody>

    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="50%">
      </colgroup>
      <tr class="cate">
        <td colspan="2">출생정보</td>
      </tr>
      <tr class="title">
        <td>분만정보</td>
        <td>출산체중</td>
      </tr>
      <tr>
        <td>{{survey.birth_type}}</td>
        <td>{{survey.birth_weight}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <tr class="cate">
        <td colspan="5">현재 함께 거주하는 가족정보</td>
      </tr>
      <tr class="title">
        <td>조부모</td>
        <td>부모</td>
        <td>형제</td>
        <td>애완동물</td>
        <td>총</td>
      </tr>
      <tr>
        <td>{{ survey.grandparent_num }}</td>
        <td>{{ survey.parent_num }}</td>
        <td>{{ survey.brother_num }}</td>
        <td>{{ survey.pet_num }}</td>
        <td>{{ survey.family_num }}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
      </colgroup>
      <tr class="cate">
        <td>수유형태</td>
      </tr>
      <tr>
        <td>???{{ survey.like_babyfood }}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="33.3%">
        <col width="33.3%">
      </colgroup>
      <tr class="cate">
        <td colspan="3">샘플 채취 당시 변 색깔 / 변 횟수/ 변 묽기</td>
      </tr>
      <tr class="title">
        <td>아기 변 색깔</td>
        <td>변 횟수</td>
        <td>변 상태</td>
      </tr>
      <tr>
        <td>?</td>
        <td>?</td>
        <td>?</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <tr class="cate">
        <td colspan="4">아기의 건강 “수면”</td>
        <td>아기의 건강 “위생”</td>
      </tr>
      <tr class="title">
        <td>밤 낮이 바뀌어 잔다</td>
        <td>자다가 자주 깬다</td>
        <td>잠들기 전 보챔이 심하다</td>
        <td>총 수면 시간</td>
        <td>평균 목욕 횟수</td>
      </tr>
      <tr>
        <td>{{ survey.sleep1 }}</td>
        <td>{{ survey.sleep2 }}</td>
        <td>{{ survey.sleep3 }}</td>
        <td>{{ survey.sleep4 }}</td>
        <td>??</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="33.3%">
        <col width="33.3%">
      </colgroup>
      <tr class="cate">
        <td colspan="3">아기의 건강 “감성”</td>
      </tr>
      <tr class="title">
        <td>조용하고 침착하다</td>
        <td>보채는 편이다</td>
        <td>잘 웃는다</td>
      </tr>
      <tr>
        <td>{{survey.sens1}}</td>
        <td>{{survey.sens2}}</td>
        <td>{{survey.sens3}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="20%">
        <col width="20%">
        <col width="20%">
        <col width="20%">
      </colgroup>
      <tr class="cate">
        <td colspan="5">아기의 건강 “건강”</td>
      </tr>
      <tr class="cate">
        <td>최근 2주이내<br>병원에 간적이 있다면</td>
        <td>가장 빈번하게 병원을<br>찾는 이유는?</td>
        <td>항생제 복용여부</td>
        <td>부모가 염려하는<br>아이의 건강</td>
        <td>아이에게 챙겨 먹이는<br>건강식</td>
      </tr>
      <tr>
        <td>{{survey.disease_weekly}}</td>
        <td>{{survey.disease_frequently}}</td>
        <td>{{survey.disease_chronic || "-"}}</td>
        <td>{{survey.disease_worry}}</td>
        <td>??</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody>
      <colgroup>
        <col width="">
        <col width="50%">
      </colgroup>
      <tr class="cate">
        <td colspan="2">가족정보</td>
      </tr>
      <tr class="title">
        <td>엄마</td>
        <td>아빠</td>
      </tr>
      <tr>
        <td>나이 {{ survey.mother_age }}세, 흡연 {{ survey.mother_smoke }}</td>
        <td>나이 {{ survey.father_age }}세, 흡연 {{ survey.father_smoke }}</td>
      </tr>
      </tbody>
    </table>

    <table class="surveytb surveytb_2">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="cate">
        <td colspan="6">가족력</td>
      </tr>
      <tr class="title">
        <td>혈관 심장질환<span>(고혈압, 고지혈증, 심근경색 등)</span></td>
        <td>위장관 질환<span>(위염, 궤양, 과민성대장염 등)</span></td>
        <td>호흡기 질환<span>(폐렴, 천식, 만성폐질환 등)</span></td>
        <td>신장질환<span>(신부전, 신증후군 등)</span></td>
        <td>알레르기 질환<span>(신부전, 신증후군 등)</span></td>
        <td>내분비<span>(비만, 당뇨, 갑상선 등)</span></td>
      </tr>
      <tr>
        <td>{{survey.hypertension || "-"}}</td>
        <td>{{survey.heart_disease || "-"}}</td>
        <td>{{survey.stroke || "-"}}</td>
        <td>{{survey.breast_cancer|| "-"}}</td>
        <td>{{survey.lung_cancer|| "-"}}</td>
        <td>{{survey.diabetes || "-"}}</td>
      </tr>
      </tbody>
    </table>
    <table class="surveytb surveytb_2">
      <tbody></tbody>
      <colgroup>
        <col width="">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
        <col width="16.6%">
      </colgroup>
      <tbody>
      <tr class="title">
        <td>뇌질환<span>(치매, 뇌졸중, 간질 등)</span></td>
        <td>각종 암<span>(폐암, 위암, 간암 등)</span></td>
        <td>류마치스, 면역질환</td>
        <td>감염질환</td>
        <td>기타</td>
        <td></td>
      </tr>
      <tr>
        <td>{{survey.hyperlipidemia|| "-"}}</td>
        <td>{{survey.osteoporosis|| "-"}}</td>
        <td>{{survey.colorectal_cancer|| "-"}}</td>
        <td>{{survey.thyroid_cancer|| "-"}}</td>
        <td>{{survey.stomach_cancer|| "-"}}</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>
  </section>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_1.png" alt="balance1 성장"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_box">
      <ul class="three">
        <li>
          <span><em>· Sample ID</em>{{sample.code}}</span>
          <span><em>· 작성일</em>{{moment(new Date()).format("YYYY년 M월 D일")}}</span>
          <span><em>· 이름</em>{{survey.name}}</span>
          <span><em>· 나이</em>{{survey.age}}</span>
        </li>
        <li>
          <span><em>· 생년월일</em>{{survey.birthday_year+"년 "+survey.birthday_month+"월 "+survey.birthday_day + "일"}}</span>
          <span><em>· 신장</em>{{survey.height}}cm</span>
          <span><em>· 체중</em>{{survey.weight}}kg</span>
          <span><em>· BMI</em>{{survey.bmi}}</span>
        </li>
        <li>
          <span><em>· 분만정보</em>{{survey.birth_type}}</span>
          <span><em>· 출산체중</em>{{survey.birth_weight}}kg</span>
          <span><em>· 산후조리</em>{{survey.postnatalcare}}</span>
          <span><em>· 가족정보</em>총 {{survey.parent_num + survey.brother_num}}명 (부모{{survey.parent_num}},형제{{survey.brother_num}})</span>
        </li>
      </ul>
    </div>
    <div class="data_box">
      <ul>
        <li style="height: 550px;">
          <h3>키성장</h3>
          <span class="graph_area_grow">  <v-chart style="left:-38px;"  :options="heightChartData" :init-options="{ width: 500, height : 423}" theme='shine' /></span>
          <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
        </li>
        <li>
          <h3>체중의 변화</h3>
          <span class="graph_area_grow"> <v-chart style="left:-25px;" :options="weightChartData" :init-options="{ width: 510, height : 423}" theme='shine' /></span>
          <span class="data_txt mint">36개월 여아의 범위는 80~100cm 로 현재 93cm로 연령대의 하위에서 점차 평균치로 상승중입니다.</span>
        </li>
      </ul>
      <div class="txt_area margintop-1">
        키는 유전의 영향을 가장 많이 받습니다. 하지만 환경적인 요인도  무시할 수 없습니다.<br>
        성장을 위해 아이들에게 가장 중요한 요소는 영양, 운동, 숙면, 스트레스 그리고 질병입니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				충분한 영양섭취는 뼈와 근육을 키워 키가 잘 자라도록 돕습니다. 가장 중요한 것은 단백질과 칼슘이지만 치우치지 않는 것도 중요합니다.<br><br>
				성장호르몬의 분비는 키 성장의 핵심입니다. 주로 잠자는 시간에 가장 많이 분비되기 때문에 충분한 수면시간을 확보하고 적절한 시간에 숙면을 취하는 것이 무척 중요합니다. 밤 11부터 4~5시간이 제일 중요합니다.<br><br>
				장 미생물의 관점에서 볼 때 돌 이전의 아기는 장이 완성되기 전 단계이기 때문에 돌발적인 트러블 이후의 회복 관리가 매우 중요합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>5</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->


  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_baby2.png" alt="수면"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="survey_box">
      <ul class="two">
        <li>
          <span><em>· 밤낮이 바뀌어 잔다 </em>{{survey.sleep1}}</span>
          <span><em>· 자다가 자주 깬다</em>{{survey.sleep2}}</span>
        </li>
        <li>
          <span><em>· 잠들기 전 보챔이 심하다</em>{{ (survey.age < 18)?survey.sleep3:'성인은 sleep 항목이 한개 없음'}}</span>
          <span><em>· 하루동안 총 수면 시간은?</em>{{(survey.age < 18)?survey.sleep4:survey.sleep3}} 시간</span>
        </li>
      </ul>
    </div>
    <div class="data_box">
      <ul class="food_data_graph">
        <li>
					<span class="title">
						연령별 적정 수면시간
					</span>
          <div class="graph_area_living">
            <v-chart :options="sleep"  :initOptions="{
            width: 500, height : 300 }" theme='shine' />
          </div>
        </li>
        <li class="sleep">
          <div>
					<span class="sleep_age">
						<span>
							<em>영아 만 1세미만</em>하루 14~17시간, 12~15시간
						</span>
					</span>
            <ul>
              <li>
                <span><em>· 신생아 (0~3개월) :</em> 하루 14~17시간</span>
                <span><em>· 아기 (4~11개월) :</em> 12~15시간</span>
                <span><em>· 유아 (1~2세) :</em> 11~14시간</span>
                <span><em>· 초등학생 (6~13세) :</em> 9~11시간</span>
              </li>
              <li>
                <span><em>· 청소년 (14~17세) :</em> 8~10시간</span>
                <span><em>· 25세 이하 성인 (18~25세) :</em> 7~9시간</span>
                <span><em>· 성인 (26~64세) :</em> 7~9시간</span>
                <span><em>· 노인 (35세 이상) :</em> 7~8시간</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="txt_area">
        갓 태어난 아기는 종일 잠만 잡니다. 엄마 배 속에서 세상으로 나오는 과정에서 겪었을 큰 스트레스를 해소하기위해 아기는 많이 자는 게 좋습니다. 자는 동안 아기의 뇌가 발달됩니다.<br><br>
        월령이 증가하면서 밤낮이 바뀐 수면습관이 정상적으로 돌아오면서 수면 패턴이 만들어집니다.
        좋은 잠은 뇌 성장 뿐아니라 키성장에도 중요한 영향을 미칩니다.

      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
					만일 아이들이 효과적으로 잠을 자지 않는다면 성장 호르몬은 조금씩 하루 종일 분비되며 최고 농도에 이르지 못하게 되어 효과적인 성장 자극이 일어나지 않게 됩니다.<br><br>
					또 다른 연구에 따르면 수면 장애가 미생물의 구성과 건강에 영향을 미쳐 전반적인 건강에 큰 영향을 줄 수 있으며 반대로 장내 미생물이 수면의 질에 영향을 줄 수도 있습니다.<br><br>총 미생물군 다양성은 수면 효율성 증가 및 총 수면 시간의 양과 상관관계가 있습니다. 장내 미생물은 면역 조절 경로, 신경 내분비 경로 및 미주 신경 경로를 통해 뇌 기능에 영향을 미치며, 이 세 가지 모두 양방향 흐름을 가지고 있습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>10</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <section class="cover">
    <div><img src="img/report_information3.jpg"></div>
  </section>
  <!-- 페이지끝 -->


  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_baby3.png" alt="가족력"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="survey_title"><img src="img/ico_check.png" alt="체크">Sample 제출시 작성하신 설문을 요약한 Page 입니다. 당신의 일상이 건강의 시작이자 마지막입니다. </div>
    <div class="survey_box">
      <ul class="three family_history">
        <li class="">
          <div></div>
          <span class="tit">엄마의 건강</span>
          <span><em>· 신장</em>{{survey.mother_height}}cm</span>
          <span><em>· 나이</em>{{survey.mother_age}}세</span>
          <span><em>· 몸무게</em>{{survey.mother_weight}}kg</span>
          <span><em>· 흡연</em>{{survey.mother_smoke}}</span>
        </li>
        <li class="">
          <div></div>
          <span class="tit">아빠의 건강</span>
          <span><em>· 신장</em>{{survey.father_height}}cm</span>
          <span><em>· 나이</em>{{survey.father_age}}세</span>
          <span><em>· 몸무게</em>{{survey.father_weight}}kg</span>
          <span><em>· 흡연</em>{{survey.father_smoke}}</span>
        </li>
        <li class="data">
          <div></div>
          <span class="tit">가족력</span>
          <span>
						<em>· 내분비 (비만,당뇨,갑상선 등)</em>
						<span class="answer">??{{survey.diabetes || "-"}}</span>
					</span>
          <span>
						<em>· 위장관 질환 (위염,궤양,과민성대장염 등)</em>
						<span class="answer">{{survey.heart_disease || "-"}}</span>
					</span>
          <span>
						<em>· 내분비 (비만,당뇨,갑상선 등)</em>
						<span class="answer">??{{survey.diabetes || "-"}}</span>
					</span>
        </li>
      </ul>
    </div>
    <div class="data_box">
      <div class="txt_area margintop0">
        <span>질병,가족력</span><br>
        가족력은 후천적인 유전 혹은 사회적 유전이라 부를 수 있습니다.<br>선천적인 유전병인 다운증후군이나 색맹, 혈우병에 비해 가족력은 고혈압, 당뇨, 고지혈증, 심장병, 탈모, 암 등이 대표적입니다.<br><br>
        심장병은 가족 중 환자가 있을 때 발병률이 2배 이상이며 당뇨병 역시 부모가 당뇨병이 있을경우 30~40% 더 발병률이 높습니다. 암의 경우에도 부모가 암일경우 자식의 발병률이 2~5배 높은 것으로 국제 암학회가 밝히고 있습니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				가족력에서 제일 중요한 부분이 생활 습관입니다.<br><br>

				특히 같이 식생활을 하는 ‘식구’는 유사한 식이 취향을 가지게 되며 장기간 지속되면 장의 환경 즉 장 미생물의 생태계가 유사해지기도 합니다. 장 미생물의 생태계는 대장암이나 당뇨와 매우 관계가 높다고 알려져 있으며 이것이 식습관과 매우 관련이 높은 것으로 판단하고 있습니다.<br><br>

				좋은 식습관을 가지도록 하는 밥상머리 교육이 그만큼 중요하다는 의미입니다. 하지만 부모의 그릇된 지식이나 선입견 때문에 잘못된 식습관을 갖게 되는 경우도 있습니다.<br><br>

				장 미생물 검사는 그런 측면에서도 매우 중요합니다. 그 동안 내 생활습관이 몸에 올바로 영향을 미쳤는지 확인할 수 있습니다.<br>
				돌 이전의 아기는 아직 사회전 유전이 시작되기 전 단계이며, 고형식을 시작하는 단계에서부터 좋은 습관을 가지도록 부모의 바른 지도가 필요합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>13</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
  </div>
  <section>
    <ul class="graph_area_baby">
      <li>
        <span><v-chart :options="babyBsChartData.milk"  :initOptions="{
            width: 239, height : 654 }" theme='shine' />
        </span>
        <strong>[모유유래]</strong></li>
      <li>
        <span>
          <v-chart :options="babyBsChartData.beneficial"  :initOptions="{
              width: 239, height : 654 }" theme='shine' />
        </span>
        <strong>[유익균]</strong></li>
      <li>
        <span>
          <v-chart :options="babyBsChartData.chance"  :initOptions="{
              width: 239, height : 654 }" theme='shine' />
        </span>
        <strong>[기회감염균]</strong></li>
      <li>
        <span>
          <v-chart :options="babyBsChartData.diversityByAge"  :initOptions="{
                width: 239, height : 654 }" theme='shine' />
        </span>
        <strong>[다양성]</strong></li>
    </ul>
    <div class="txt_area">
      아기의 장은 아직 성숙되기 전입니다.<br>
      백지에 그림을 채워 가는 중이기 때문에 많은 변수가 있으며 지속적으로 변화할 것입니다.<br><br>

      본 분석은 아기에게서 볼 수 있는 몇가지 특징을 모아 분석한 내용입니다.<br>
      위 그래프에서 막대는 아기들의 통상적인 분포 수준이며 마크는 본 sample의 분석 결과입니다. <br><br>

      다양성이 평균보다 낮고 기회감염균이 좀 많은 것이 병을 의미하는 것은 아닙니다.
      아주 많은 요인들이 작용하는 복합적인 결과이므로 고형식 이후에 변화를 관찰해보는 것이 좋습니다.
    </div>
    <div class="tip_box">
      <div>
				<span>
				AiB는 평균적인 한국인의 장내 미생물 분포를 조사하여 각 미생물의 기능을 연구 논문을 근거로 BSC(Balanced Score Card) 및 Block Chain 방식으로 Scoring 한 결과를 Ai를 이용하여 분석 합니다. 하지만 이 결과를 직접적인 병의 원인이라 결과로 해석할 수는 없습니다. 장내 미생물이 중요하지만 건강의 요소중 하나일 뿐이므로 본 결과를 토대로 의사와 상의하는게 좋겠습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>8</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_summary.png" alt="balance summary"><span class="miso"></span></h2>
  </div>
  <section class="margin0">
    <ul class="balance_summary_baby">
      <li>
        <ul>
          <li><strong></strong></li>
          <li>
            Veillonella<br>
            Streptococcus
          </li>
          <li>
            최신 연구에 의하면 모유에 가장 많이 나오는 균들입니다.<br>
            특히 베이요넬라(Veillonella)균은 대부분 이유식 이전의 아기한테 가장 많이 보이는 균입니다.<br><br>

            스트렙토코쿠스(Streptococcus)는 유익균과 유해균이 함께 포함되어 있습니다. 일부는 Probiotics로 사용될 수 있고 일부는 유해균이 되는 경우도 있어 공생균으로 분류합니다.<br><br>

            <a href="#">Ref :  Mom and baby share “good bacteria” through breastmilk – Children’s Hospital Research Institute of Manitoba (chrim.ca)
            </a>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li><strong></strong></li>
          <li>
            Faecalibacterium<br>
            Akkermansia<br>
            Lactobacillus<br>
            Roseburia<br>
            Bifidobacterium

          </li>
          <li>
            유익균은 체내에서 대체로 건강에 이로움을 주는 균들을 뜻합니다.<br>
            유익균의 여러가지 기능 중 가장 중요한 것은 유해균의 증식을 억제하는데 가장 큰 역할이 있습니다.<br>
            장내 미생물은  단순한 장 건강 외에도 우리 신체 모든 부위에 다양하게 영향을 미치고 있습니다. 면역, 콜레스테롤, 아토피, 유당불내증 등을 개선하거나 강화합니다.<br><br>

            락토바실러스(Lactobacillus)와 비피더스(Bifidobacterium)은 대표적인 유산균으로서 모유에서 가장 좋은 영향을 주는 유익균으로 알려져 있습니다.<br>
            나머지 균들도 역시 SCFA(단쇄지방산)을 만들어 내는 균들입니다.<br>
            단쇄지방산은 장에서 유해균의 성장을 억제하는 중요한 물질입니다
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li><strong></strong></li>
          <li>
            Citrobacter<br>
            Escherichia<br>
            Klebsiella<br>
            Clostridium<br>
            Fusobacterium<br>
            Collinsella<br>
            Enterobacter<br>
            Campylobacter
          </li>
          <li>
            기회감염균은 건강한 사람에게는 감염 증상을 유발하지 않지만 쇠약하거나 면역기능이 감소된 사람에게 감염 증상을 일으키는 균입니다.<br><br>

            아기의 장은 유해균이 호시탐탐 기회를 노리고 있습니다.<br>
            아직은 빈 자리가 많은 기회의 땅입니다.<br>
            이런 균들이 당신의 아기를 노리고 있습니다.<br><br>
            특히 시트로박터(Citrobacter)와 같은 균은 제왕절개 수술을 통해 낳은 아기의 장에서 더 많이 발견됩니다.
            영유아 시기에 많이 발견되는 균들을 모아보았습니다.<br>
            대부분의 기회감염균은 제대로 먹이고 운동하면 성장 중에 점차 줄어들게 됩니다.
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>9</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_aibchart.png" alt="aibchart"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box data_box_graph">
      <h3>임상적 분류</h3>
      <div class="graph_area_diversity">
        <v-chart :options="aibChart1" ref="polar" theme='shine' :initOptions="{width: 1038}"/>
      </div>
      <h3>생물학적 분류</h3>
      <div class="graph_area_diversity">
        <v-chart :options="aibChart2" theme='shine' :initOptions="{width: 1038}"/>
      </div>
      <div class="txt_area">
        AiB Chart는 AiBiotics사의 독창적인 Microbiome 지도입니다.
        수백여 종의 장내 미생물을 보편적으로 많이 존재하는 순서로 나열한 다음 나의 수준을 비교하는 그래프입니다. 보편적으로 존재하는 장 미생물이 결핍되거나, 혹은 특정한 균이 특이하게 많이 존재하는 경우를 살펴보는 방법입니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				임상적 분류 : 많은 연구를 통해 유해성이 보고되거나, 유익성이 보고된 경우 유해균과 유익균으로 구분하였습니다. 하지만 유해균으로 분류된 붉은색 선이 반드시 병을 유발한다는 증거는 없습니다. 하지만 그럴 확률은 높은게 사실입니다. 또한 결핍이 많은 경우에도 역시 임상적으로 여러가지 증상을 보이는 경우가 많습니다.<br>
				생물학적 분류 : 장 미생물의 여러가지 분류 기준 중에서 Phylum(문)기준으로 분류한 그래프입니다. 생물학적인 구분기준으로 나누었지만 각 균들의 특성들이 있어 장 건강을 일부 대면하기도 합니다. 박테로이데테스(Bacteroidetes)와 퍼미큐티스(Firmicutes)가 가장 보편적으로 많은 균입니다. 이외에 프로테오박테리아나 푸조박테리아에는 병원성 균이 많이 포함되어 있습니다.<br>
          <p>하지만 돌 이전 특히 고형식 이전의 아기는 위의 일반적인 기준으로 평가하기 어렵습니다. 아직은 장이 완성되지 않았기 때문에 유해균이 많아지지 않도록 주의하는 것이 더 중요합니다.</p>
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>16</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_index.png" alt="AiBINDEX"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box index">
      <ul class="food_data_graph aib_index">
        <li>
          <div id="now"></div>
          <div class="index_old">
            <span class="title">한국인 나이별 다양성 분포</span>
            <div>
            <v-chart :options="byAgeDiversity" :init-options="{width:500, height:300}"  />
            </div>
          </div>
        </li>
        <li>
          <span class="title">AiB index</span>
          <div class="graph_area_living_freq">
            <v-chart :options="freqDistributionListChart" :init-options="{width:520, height:344}" theme='shine' />
          </div>
        </li>
      </ul>
      <ul class="index_result">
        <li>
          <span class="standard">총 검사 대상 균종 <em>{{aibTotal.avg||0}}</em>개 중</span>
          <span>확인된 균종 <em>{{aibTotal.user||0}}</em>종</span>
        </li>
      </ul>
      <div class="txt_area">
        다양성 분포는 AiB index를 이용한 히스토그램으로 표현하고 있습니다. 전체 분포 중에서 당신의 다양성의 위치를 확인하세요.<br><br>
        장 미생물의 분석에서 다양성을 매우 중요한 Index로 여기고 있지만 그 정확한 기준이 없습니다. 그저 많기만 하다고 좋은 것만은 아닙니다.
        AiB index는 '과잉'을 제외한 분포를 Log 함수를 이용하여 다양성 지수를 산출하고 있습니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				장 미생물은 사람들이 먹은 음식을 나눠 먹지만 나름 자기들만의 식성이 있어 좋아하는 먹이가 균들마다 조금씩 다릅니다. <br>
        대개 좋은 균들이 좋아하는 음식을 ‘건강식’이라고 하죠.
				그런데 건강식을 아기들은 잘 먹지 않습니다. 불량식품보다 맛이 <br>없기 때문인데요, 어릴때 부터 건강식에 입맛을 길들이는 건 너무나 중요합니다.<br>
				아기에게 건강식을 길들이는 가장 좋은 방법은 온 식구가 모두 건강식에 길들여지는 방법입니다.
				</span>
      </div>
    </div>
    <div class="index_bobg_baby"></div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>17</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="txt_top">
      Microbiome 의 생태학적인 분류로 20여개의 Phylum(문) Level의 분류법이 있습니다.<br>
      Bacteroidetes와 Firmicutes가 가장 보편적인 균들이며 유익균, 유해균, 공생균이 섞여있습니다.<br>
      반면에 Fusobacteria와 ProteoBacteria는 유해성이 큰 균들이 다수 포함되어 있어 일반적으로 유해균으로 분류합니다.<br>
      Major 균종의 비율과 소수 균종이 특이하게 증가될 떄 자세하게 확인해 보아야 합니다.
    </div>
    <div class="analysis_box">
      <ul>
        <li>
          <v-chart :options="phylum.avgPieData" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
        <li>
          <v-chart :options="phylum.userPieData" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
      </ul>
    </div>
    <dl class="analysis_list">
      <dt>
        <strong>Actinobacteria</strong>
        <span>
          <v-chart :options="reportPhylum.Actinobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        악티노박테리아(Actinobacteria)의 대표 선수는 비피더스라고 불리는 비피도박테리아(Bifidobacteria) 입니다. <br>
        비피더스는 대표적인 유산균으로서 장 건강에 도움이 되는 균으로 규명되어 먹으면 건강에 도움을 주는 균인 <br>‘프로바이오틱스’로 인정받고 있습니다. 비피더스외에도 많은 종류의 균들이 있으며 공생균, 유해균도 다수 포함되어 있습니다.
      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Firmicutes</strong>
        <span>
          <v-chart :options="reportPhylum.Firmicutes"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        퍼미큐티스( Firmicutes)는 마이크로바이옴 분석시 가장 많은 종류가 발견되는 균종입니다.<br>
        지방대사와의 관련성 때문에 비만균이라는 오명을 쓰고 있지만 실제로는 락토바실러스를 비롯한 다양한 <br> 유산균이 포함되는 그룹입니다. 유익균과 유해균이 모두 포함되어 있습니다. 하지만 microbiome 검사에서 <br> 가장 중요한 ‘다양성’의 측면에서 기여도가 가장 큰 그룹입니다.
      </dd>
    </dl>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>18</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_phylum.png" alt="phylum분석"><span class="miso"></span></h2>
  </div>
  <section>
    <dl class="analysis_list nextPage">
      <dt>
        <strong>Bacteroidetes</strong>
        <span>
          <v-chart :options="reportPhylum.Bacteroidetes"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        박테로이데테스(Bacteroidetes)의 세부종은  퍼미큐티스(Firmicutes)보다 작지만 가장 보편적인 장 미생물의 그룹입니다.<br><br>

        세부종은 83종으로 Firmicutes보다 작지만 가장 보편적인 장 미생물의 그룹입니다.<br>
        고형식을 시작하는 아이들이 가장 먼저 발견되는 균종으로 양만으로 따지면 전체의 절반을 차지하고
        있습니다. 대부분이 공생균이지만 일부 세부 균종 중에는 유익균과 유해균이 공존합니다.
      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Proteobacteria</strong>
        <span>
          <v-chart :options="reportPhylum.Proteobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        프로테오박테리아(Proteobacteria)는 성인에서 대략 10% 정도 분포합니다. 대부분 공생균이지만 다른 그룹 대비 유해균이 많은 그룹입니다.<br><br>

        프로테오박테리아(Proteobacteria)와 푸조박테리아는(FusoBacteria)는 주로 병원성균을 많이 포함하고 있습니다. 수치적으로도
        전체 분포에서 푸조는 1%미만, 프로테오는 10%수준이며 적극적으로 관리해야 하는 대상입니다.<br><br>
        특히 병원성이 확인된 균들은 작을수록 좋다고 볼 수 있습니다.
      </dd>
    </dl>
    <dl class="analysis_list">
      <dt>
        <strong>Fusobacterium</strong>
        <span>
          <v-chart :options="reportPhylum.Fusobacteria"  :init-options="{width: 290, height: 180}" />
        </span>
      </dt>
      <dd>
        푸조박테리아는(FusoBacteria)는 주로 병원성 균을 많이 포함하고 있습니다.<br>
        장이 불안정한 아기들도 70~80%는 발견되지 않습니다.<br>
        발견되는 것만으로도 경고신호로 보는 것이 좋겠습니다.<br><br>
        특히 병원성이 확인된 균들은 작을수록 좋다고 볼 수 있습니다.
      </dd>
    </dl>
    <div class="tip_box">
      <div>
				<span>
				Phylum은 생물을 분류하는 기준 중에서 아주 큰 분류 기준입니다.<br>
				예를 들어 문어, 오징어는 연체동물문이며, 메뚜기, 지네등은 절지동물문이고, 인간은 금붕어, 상어, 늑대, 곰과 같은 <br> 척삭동물문에 속합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>19</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="txt_top">
      장유형은 장내 세균의 분포를 기준으로 판단하는 패턴입니다.<br>
      식이패턴, 운동등의 생활습관, 약물의 복용등의 다양한 요인에 의하여 만들어 지는 장의 유형을 나타냅니다
    </div>
    <div class="analysis_box">
      <ul>
        <li>
          <v-chart :options="enterotypePieChart.avg" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
        <li>
          <v-chart :options="enterotypePieChart.user" :init-options="{width: 519, height: 473}" theme='shine' />
        </li>
      </ul>
    </div>
    <div class="data_box">
      <div class="txt_area">
        박테리오데스(Bacteriodes), 프리보텔라(Prevotella), 루미노코커스(Ruminococcus) 3종의 대표적인 균종의 비율로 장유형을 구분합니다.<br><br>

        박테리오데스(Bacteriodes)는 육류와 정제된 탄수화물(쌀밥,국수,빵)을 주요한 재료입니다. 서구인의 일반적인 유형이며 현대의 한국인이 가장 많은 유형에 속합니다.<br><br>
        프리보텔라(Prevotella)는 식이 섬유의 섭취가 많은 사람에게 주로 나타납니다. 아프리카, 아마존에서 채식을 위주로 하는 경우 주로 나타납니다. 루미노코커스(Ruminococcus)는 지방이나 당의 대사 작용이 커서 흔히 뚱보균이라고 불립니다.
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
				Entrotype은 주로 장기적인 식이 취향에 의하여 장 미생물의 우점종이 결정되는 경우입니다.<br><br>하지만 가끔 항생제를 복용한 후에 프리보텔라만 상대적으로 작아지는 경우도 있기 때문에 선택적인 항생제나 다른 원인으로 인하여 식습관을 반영하지 못하는 경우가 발생하기도 합니다. 따라서 장내 미생물 검사시 최근 투약한 이력을 잘 파악하는 <br> 것이 중요합니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>20</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_entero.png" alt="장유형"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_entero">
      <ul class="data_entero_list">
        <li>
          <strong>Prevotella</strong>
          <em>프로보텔라</em>
          <span>
            <v-chart :options="reportGenus.Prevotella"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
        <li>
          <strong>Bacteriodes</strong>
          <em>박테리오데스</em>
          <span>
            <v-chart :options="reportGenus.Bacteroides"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
        <li>
          <strong>Ruminococcus</strong>
          <em>루미노코커스</em>
          <span>
            <v-chart :options="reportGenus.Ruminococcus"  :init-options="{width: 290, height: 180}" />
          </span>
        </li>
      </ul>
      <div> [ 각 장유형의 나이별 TREND 와 나의 위치 ] </div>
    </div>
    <div class="data_box">
      <div class="txt_area">
        연령별로 각 장의 유형을 분류해보면 프리보텔라의 나이별 증가  TREND 가 뚜렷하게 나타납니다.<br>
        나이가 들면서 식이 섬유의 섭취가 증가되는 한국인의 식생활 패턴이 보여집니다.<br><br>
        장유형은 장기적인 식이 습관이 가장 중요한 요소라고 연구된 결과가 있습니다.<br>
        일시적으로 식사 패턴을 바꾸면 일시적으로 장 유형이 식이에 따라 변화 하지만 원래대로 돌아오면 장 패턴 역시 금방 원복 되는 결과 역시 <br>보고되었습니다.<br><br>
        이 패턴이 건강의 절대적인 척도는 아닙니다. 프리보텔라균이 채식인에게 주로 나타나고 , 유익한 단쇄지방산을 생산하지만 박테리오데스에서도 <br> 단쇄지방산은 생산이 됩니다.<br><br>
        다만 식이 섬유의 섭취가 작을 때는 변비가 생길 수 있고 또한 식이 섬유는 다양한 유익균의 먹이가 되기 때문에 식이 섬유의 섭취정도를 대변하는 <br>프리보텔라의 양이 작을 때는 장기적인 관점에서 개선할 필요성을 조언할 수 있습니다.
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>21</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_lactobacilli.png" alt="유산균 분석"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="data_box data_box_graph">
      <h3>Bifidobacterium</h3>
      <div class="graph_area_diversity">
        <v-chart :options="bifiSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
      </div>
      <h3>Lactobacillus</h3>
      <div class="graph_area_diversity">
        <v-chart :options="lactoSpeciesChart" :initOptions="{ height: '400px', width: '1038px'}"/>
      </div>

      <div class="txt_area">
        대표적인 유산균인 비피더스균과 락토바실러스의 세부종의 분포를 분석합니다.<br><br>
        <span class="mint">
				고객님의 경우 비피더스보다는 락토바실러스가 부족한것으로 보여집니다.<br>
				락토바실러스는 적절한 처방을 통해  다양한 방법으로 섭취할 수 있습니다.
			</span>
      </div>
    </div>
    <div class="tip_box">
      <div>
				<span>
  대표적 두 가지 유산균인 락토바실러스와 비피더스는 각각 소장과 대장을 책임지는 건강의 파수꾼입니다. 락토바실러스는 면역과 항균효과에 특성이 있으며 비피더스는 대장의 면역 및 장 운동 및 배변에 도움이 된다고 알려져 있습니다. <br>
  이런 유익균은 프로바이오틱스라 칭하고 프로바이오틱스의 먹이를 프리바이오틱스, 프로바이오틱스의 대사산물을 포스트바이오틱스라고 합니다. 모두 다 자기 역할이 있는 겁니다. <br><br>

  유산균은 장이란 밭에 뿌려지는 씨앗과 같습니다. 좋은 씨앗이 물론 중요하지만 우선 자신한테 잘 맞아야 하고, 또 좋은 비료와 양분이 필요합니다. 비료와 양분이 되는 것이 프리바이오틱스와 음식입니다. <br>
  그리고 마지막으로 필요한 물과 햇빛은 운동과 생활 습관입니다. 이 중 하나라도 부족하면 씨앗은 그저 씨앗일 뿐 열매가 되지 못하는 이치와 같습니다.유산균만 먹는다고 장이 좋아지고 변비가 해결되기 어렵습니다.
				</span>
      </div>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>22</span></li>
    </ul>
  </div>
  <!-- 페이지끝 -->

  <!-- 페이지시작 -->
  <div class="header">
    <h2><img src="img/title_microbiome_summary.png" alt="Comment"><span class="miso"></span></h2>
  </div>
  <section>
    <div class="txt_area">
      <p v-html="sample.summary"></p>
    </div>
  </section>
  <div class="footer">
    <ul>
      <li><img src="img/footer_logo.png" alt=""></li>
      <li><span>17</span></li>
    </ul>
  </div>


  <!-- 페이지시작 -->
  <section class="full">
    <div class="back_logo"></div>
    <div class="bo_homepage">www.aibiotics.kr</div>
  </section>
  <!-- 페이지끝 -->
</div>
</template>
// striped
<script>
import Vuetable from 'vuetable-2'
import { mapActions } from 'vuex'
import moment from 'moment'
import CssConfig from '../theme/vueTableCssBootstrap4.js'
import FoodTable from './FoodTable'
export default {
  components : {
    Vuetable,
    FoodTable
  },
  name: "report",
  data () {
    return {
      babyBsChartData: {
        milk : {},
        beneficial: {},
        chance: {},
        diversityByAge: {}
      },
      bIndexStr: '',
      bIndexClass: '',
      moment : moment,
      sample: {},
      css: CssConfig,
      balance: {},
      starChart : {},
      species : {},
      survey : {},
      sleep : {},
      heightChartData : {},
      tastesList : {},
      aibChart1 : {},
      aibChart2 : {},
      byAgeDiversity : {},
      freqDistributionListChart : {},
      phylum : {},
      reportPhylum : {},
      reportGenus : {},
      enterotypePieChart : {},
      balanceGauge : {
        size : {
          width: 400,
          height : 400
        },
        gut : {}
      },
      phylumScatter: {
      },
      bifiSpeciesChart : {},
      lactoSpeciesChart : {},
    }
  },
  methods : {
    ...mapActions(['speciesAction', 'getSample', 'getPhylumChartData','lactoDetail', 'getBabyBalanceSummaryData']),
    dataManager (s, p) {
      console.log("key " , s[0].key);
      let tableData = {};
      try {
        tableData = this.species.tableData[s[0].key];
      }catch (e){
        return tableData;
      }
      if(s.length > 0){
        //console.log('#report dataManager s', JSON.stringify(s) );
        //console.log('#report dataManager p', JSON.stringify(p) );

        tableData.sort(function(a, b) {
          let bindoA = parseInt(a.bindo);
          let bindoB = parseInt(b.bindo);

          //빈도 수 정렬
          if(s[0].direction === 'desc'){
            return bindoA > bindoB ? -1 : bindoA < bindoB ? 1 : 0;
          }

          return bindoA < bindoB ? -1 : bindoA > bindoB ? 1 : 0;
        });
console.log(p.per_page)
        // 10개 이상이면 Row 제어
        if(p.per_page >= 7) {
          tableData = tableData.slice(0, p.per_page)
        }
      }

      console.log("tableData ", tableData)
      return {
        pagination : p,
        data : tableData
      }
    },
    balanceChart() {
      Object.keys(this.balance).reduce( (acc, key) => {
        this.balance[key].series[0].detail.formatter = " ";
        //balance[key].series[1].detail.formatter = " ";
        if(key === 'totalBalanceGaugeData') this.balance[key].series[1].detail.formatter = " ";
        let centerPos = (key === 'totalBalanceGaugeData')?'60%':'75%';
        this.balance[key].series[0].center[1] = centerPos ;
        this.balance[key].series[1].center[1] = centerPos ;
        delete this.balance[key].toolbox;
      }, {})

      //this.$store.commit("setBalance", balance);
    },
    async getReportSample() {
      await this.getSample();
      this.sample = this.$store.state.sample;
    },
    async getBalanceIndex(){
      let len = this.starChart.adjustedStarScore.length;
      let bIndex = this.starChart.adjustedStarScore.reduce( (acc, obj, index) => {
        acc = acc + obj;
        if(len === (index) + 1) return acc / len;
        return acc;
      }, 0);

      console.log("get bindex !!! ", bIndex);

      switch(true){
        case (bIndex > 8.5):
          this.bIndexStr = "우수";
          this.bIndexClass = "score_a"
          break;
        case (bIndex > 6.5 && bIndex < 8.5):
          this.bIndexStr = "양호";
          this.bIndexClass = "score_b"
          break;
        case (bIndex > 4.5 && bIndex < 6.5):
          this.bIndexStr = "보통";
          this.bIndexClass = "score_c"
          break;
        case (bIndex > 3 && bIndex < 3.5):
          this.bIndexStr = "주의";
          this.bIndexClass = "score_d"
          break;
        default:
          this.bIndexStr = "경호";
          this.bIndexClass = "score_e"
          break;
      }

    },
    getReportData() {
/*
      this.getBalanceChartDataForReport().then( balance => {
        this.balance = balance;
      })
*/
      //this.balance = this.$store.state.balance;
      this.starChart = this.$store.state.starChart.bgChart;
      this.species = this.$store.state.species;
      this.survey = this.$store.state.survey;
      this.sleep = this.$store.state.sleepChartData;
      this.heightChartData = this.$store.state.heightChartData;
      this.weightChartData = this.$store.state.weightChartData;
      this.tastesList = this.$store.state.tastesList;
      this.aibChart1 = this.$store.state.aibChart1;
      this.aibChart2 = this.$store.state.aibChart2;
      this.byAgeDiversity = this.$store.state.byAgeDiversity;
      this.freqDistributionListChart = this.$store.state.freqDistributionListChart;
      this.phylum = this.$store.state.phylum;
      this.enterotypePieChart = this.$store.state.enterotypePieChart;
    },
    //species tables
    reloadTable(){
      try{
        Object.keys(this.species.tableData).forEach( key => {
          this.$refs[(key+1)].reload();
          this.$refs[(key+2)].reload();
        })
      }catch (e){
        console.log(e)
        return;
      }
    }
},
  async mounted () {
    console.log('#report mounted')
    this.getReportData();

    //this.balanceChart();
    await this.getReportSample();
    await this.getBalanceIndex();
    //await this.speciesAction(); //home > species 에서 호출
    this.$store.state.focusPageNm = "report";

    //나이별 다양성
    await this.getPhylumChartData("phylum");
    await this.getPhylumChartData("genus");
    this.reportPhylum = this.$store.state.reportPhylum;
    this.reportGenus = this.$store.state.reportGenus;

    //1세 미만 아기 적용 차트
    this.babyBsChartData = await this.getBabyBalanceSummaryData();

    //유산균
    let lactoDetailData = await this.lactoDetail();
    this.bifiSpeciesChart = lactoDetailData.speciesDetail.bifi;
    this.lactoSpeciesChart = lactoDetailData.speciesDetail.lacto;

    this.reloadTable();
  },
  created () {
  },
  updated () {
    this.$nextTick(function() {
      let divPage = document.querySelectorAll( 'div.footer>ul>li>span' );
      for ( let i = 0; i < divPage.length; i++ ) {
        divPage[i].innerText = i + 1;
      }
    });
  },
  computed : {
    aibTotal(){
      return this.$store.state.aibTotal;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../theme/reportScss';
p{text-indent: 10px;}
</style>
